import React , { useState }  from 'react';

import { Modal, Spin, Form, Select, Input } from 'antd';


import Axios from './../Axios';


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const UserRegister = ({onSuccess, children}) => {

  const [isVisible, setVisible] = useState(null);
  const [loading, setLoading] = useState(false);

  const formRef = React.createRef();

  const onFinish = values => {
    setLoading(true);
    Axios.post('/inspectors/associate',  values).then(data => {
        setLoading(false);
        setVisible(false);
        if( data.data.success == true){
            Modal.success({content: data.data.message});

            if( onSuccess)
              onSuccess(data.data);
        }else {
            Modal.error({content: data.data.message});
        }



    }).catch(e => {
          console.log('error:', e);
      setLoading(false); Modal.error({content: e.message})});


  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () =>  {
      console.log(formRef);

      formRef.current.submit();

  }
  const handleCancel = () =>  setVisible(false);


  const handleClick = () => {
      setVisible(true);
  }


  return (
    <>
      { React.cloneElement( children, { onClick: handleClick } ) }
      <Modal
        title="Asociar usuario VPA"
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Spin spinning={loading}>
          <Form
            ref={formRef}
            {...layout}
            name="basic"
            initialValues={{  role: 'admin'}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >



            <Form.Item
              label="Email"
              name="id"
              
              rules={[{ required: true, message: 'Email o ID de usuario registrado VIPA' }]}
            >
              <Input autoFocus  />
            </Form.Item>





          </Form>
        </Spin>
  </Modal></>
  );
};

export default UserRegister;
