import React , { useState, useEffect }  from 'react';

import { Modal, Spin, Form, Select, Input, Button, Checkbox } from 'antd';


import Axios from './../Axios';

const {Option} = Select;


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const ReporterEdit = ({user, visible,onSuccess, children}) => {

  const [isVisible, setVisible] = useState(null);
  const [loading, setLoading] = useState(false);

  const formRef = React.createRef();

  const onFinish = values => {
    setLoading(true);
    Axios.post('/inspectors/'+user.id+'/update', values).then(data => {
        setLoading(false);
        setVisible(false);
        Modal.success({content: '¡Usuaro actualizado!'});
        if( onSuccess)
          onSuccess(data.data);

    }).catch(e => {
      setLoading(false);

      Modal.error({content: e.response.data ?  e.response.data.message : e.message });
  });

    console.log('Success:', values);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () =>  {
      console.log(formRef);

      formRef.current.submit();

  }
  const handleCancel = () =>  setVisible(false);


  const handleClick = () => {
      setVisible(true);
  }


  return (
    <>
      { React.cloneElement( children, { onClick: handleClick } ) }
      <Modal
        title="Editar reportador"
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Spin spinning={loading}>
          <Form
            ref={formRef}
            {...layout}
            name="basic"
            initialValues={user}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Nombres"
              name="name"
              rules={[{ required: true, message: 'Nombres' }]}
            >
              <Input autoFocus />
            </Form.Item>

            <Form.Item
              label="Apellidos"
              name="last_name"
              rules={[{ required: true, message: 'Apellidos' }]}
            >
              <Input />
            </Form.Item>



            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"

              rules={[{ required: false, message: 'Please input your password!' }]}
            >
              <Input.Password   placeholder="Dejar en blanco si no cambiará" />
            </Form.Item>




    </Form>
  </Spin>
  </Modal></>
  );
};

export default ReporterEdit;
