import React , { useState, useEffect }  from 'react';

import Axios from './../Axios';
import { PlusOutlined ,EditOutlined } from '@ant-design/icons';
import {Table,Result, Button} from 'antd';

import UserRegister from '../Common/UserRegister';
import UserEdit from '../Common/UserEdit';
import RemoveUser from '../Common/RemoveUser';
import moment from 'moment';



const Users = ({payload}) => {

   const [isLoading, setIsLoading] = useState(false);
   const [registerVisible] = useState(false);
   const [editVisible] = useState(false);
   const [userList, setUserList] = useState([]);
   const [, setPagination] = useState({
     total: 50
   });


   useEffect(() => {
     customFetch();
   }, []);


   const columns = [
     {
       title: "#",
       width: 30,
       dataIndex: "id",
     },
     {
       title: "Nombre",
       dataIndex: "first_name",

     },
     {
       title: "Apellidos",
       dataIndex: "last_name",

     },

     {
       title: "Código",
       dataIndex: "code",

     },

     {
       title: "Email",
       dataIndex: "email",

     },
     {
       title: "Rol",
       dataIndex: "role",
     },

     {
       title: "Ultimo Login",
       dataIndex: "last_login_at",
       render: (value) =>  moment(value).format('LLL'),
     },
     {
       title: "Ultima Actividad",
       dataIndex: "last_activity_at",
       render: (value) =>  moment(value).format('LLL'),
     },

        payload.role === 'admin' ?{
       title: "Editar",
       key: "edit",

        render : (value, record) =>   <UserEdit user={record} onSuccess={()=>customFetch()} visible={editVisible}>
          <Button type="ghost"  style={{borderColor: '#00BCD4'}} icon={<EditOutlined /> }>Editar</Button>
           </UserEdit>,
     }:null,

        payload.role === 'admin' ?{
       title: "Eliminar",
       key: "delete",
       dataIndex: 'id',
           align: 'right',
           render: (value) =>  <RemoveUser id={value} onSuccess={()=>customFetch()}/>
     }:null,
   ];



  const customFetch = async (params = {}) => {
   console.log("params:", params);
   setIsLoading(true);
   const response = await Axios.get('/operators',{params: {
       results: 1000,
       ...params
   }});
   setUserList(response.data.data);
   setIsLoading(false);
 };


  const handleTableChange = (pagination, filters, sorter) => {
  const pager = pagination;
  pager.current = pagination.current;
  setPagination(pager);
    customFetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    });
  };

  if( payload.role !=='admin') return  <Result
    status="403"
    title="403"
    subTitle="Usted no está autorizado para ver esta página."
    extra={<Button href="/" type="primary">Regresar al inicio</Button>}
  />

  return (
    <>
      <h1 className="title-left">Operadores</h1>
      <div className="filters">



        <div style={{display: 'inline'}}>
          <UserRegister onSuccess={()=>customFetch()} visible={registerVisible}>
            <Button type="primary" icon={<PlusOutlined />}>Nuevo usuario</Button>
          </UserRegister>

        </div>


      </div>

      <Table
        scroll={{x: true}}
        rowKey={'id'}
        columns={columns}
        dataSource={userList}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={false}/>

    </>

  );

}


export default Users;
