import React, { useState } from "react";

import { Form, Spin, message, Input, Checkbox, Button, Alert } from "antd";

import { Session } from "bc-react-session";

import Axios from './../Axios'


const PasswordChange = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    Axios.post("/passwordchange",values, { crossdomain: true })
      .then((response) => {
        setLoading(false);

        const { success, data } = response.data;

        if (!success) {
          message.error(response.data.message);
          return;
        }

        Session.start({
          payload: data,
          expiration: 86400000,
        });

        window.location.href = "/";
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.response?.data.message || 'Login error');
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{padding: 20}}>
      <img
        alt="logo"
        src="/images/logo-web-white.png"
        style={{ margin: "50px auto", width: 120, display: "block" }}
      />

      <Form
       
        name="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Spin spinning={loading}></Spin>


        <Alert style={{marginBottom: 20}} message="Por tu seguridad cambia tu contraseña para acceder a VIPA" type="info"/>


        <Form.Item
          label="Contraseña nueva"
          name="password"
          extra={<span style={{fontSize: 11}}>Debe contener 10 caracteres o más, una mayúscula, una minúscula un dígito y caracter especial.</span>}
          rules={[
            {
              required: true,
              message: "Por favor ingresa una contraseña",
            },
          ]}
        >
            <Input.Password />
        </Form.Item>

        <Form.Item
          label="Repetir contraseña"
          name="password_confirmation"
          rules={[
            {
              required: true,
              message: "Por favor ingresa la misma contraseña",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item  name="remember" valuePropName="checked">
          <Checkbox>Acepto los términos y condiciones del servicio.</Checkbox>
        </Form.Item>

        <Form.Item >
          <Button type="primary" block htmlType="submit">
            Cambiar y acceder
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordChange;
