import React , { useState }  from 'react';

import { Form, Input, Button, Checkbox, Spin, message } from 'antd';

import { Redirect } from 'react-router-dom';
import {
  Session
} from 'bc-react-session';

const session = Session.get();


const Logout = () => {

  Session.destroy();

  return <Redirect to="/login"/>
};

export default Logout;
