import React , { useState }  from 'react';

import {Card} from 'antd';

const Dashboard = () => {

  return (
    <Card style={{minWidth: 290}} title="Información importante">¡Bienvenido(a) a VIPA! Cada día trabajamos para hacer que esta herramienta logre que nuestras ciudades sean mejores. Al utilizarla, tienes el compromiso de no compartir tu usuario, ni de compartir vistas/funcionalidades con personas fuera de tu organización. Es tu responsabilidad proteger nuestra propiedad intelectual y esperamos tengan un uso responsable de ella.
      Any queries are available!<br/>
    El equipo VIPA</Card>

  );

}


export default Dashboard;
