import React , { useState, useEffect }  from 'react';

import { Modal, Spin, Form, Select, Input, Button, Checkbox } from 'antd';


import Axios from './../Axios';

const {Option} = Select;


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const UserRegister = ({visible,onSuccess, children}) => {

  const [isVisible, setVisible] = useState(null);
  const [loading, setLoading] = useState(false);

  const formRef = React.createRef();

  const onFinish = values => {
    setLoading(true);
    Axios.post('/operators',  values).then(data => {
        setLoading(false);
        setVisible(false);
        Modal.success({content: '¡Registro exitoso!'});
        if( onSuccess)
          onSuccess(data.data);

    }).catch(e => {
      setLoading(false);

      Modal.error({content: e.response.data ?  e.response.data.message : e.message });
  });

}

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () =>  {
      console.log(formRef);

      formRef.current.submit();

  }
  const handleCancel = () =>  setVisible(false);


  const handleClick = () => {
      setVisible(true);
  }


  return (
    <>
      { React.cloneElement( children, { onClick: handleClick } ) }
      <Modal
        title="Nuevo usuario"
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Spin spinning={loading}>
          <Form
            ref={formRef}
            {...layout}
            name="basic"
            initialValues={{  role: 'admin', require_password_change: true}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Nombres"
              name="first_name"
              rules={[{ required: true, message: 'Please input your first name!' }]}
            >
              <Input autoFocus />
            </Form.Item>

            <Form.Item
              label="Apellidos"
              name="last_name"
              rules={[{ required: true, message: 'Please input your last name!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Código"
              name="code"
              rules={[{ required: true, message: 'Please insert code!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
        label="N.Documento"
        name="document_number"
        rules={[{ required: false}]}
      >
        <Input placeholder='DNI/RUT' />
      </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>



            <Form.Item
              label="Password"
              name="password"
              extra="Debe contener 10 caracteres o más, una mayúscula, una minúscula un dígito y caracter especial."
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>



      <Form.Item
        valuePropName="checked"
        label={<span>Cambio</span>}
        name="require_password_change"
        extra="Marca esta opción si el usuario será obligado a cambiar contraseña en el próximo inicio de sesión."
       
      >
        <Checkbox >¿Requerir cambio de contraseña?</Checkbox>
      </Form.Item>


            <Form.Item
              label="Rol"
              name="role"
              rules={[{ required: true, message: 'Please select role' }]}
            >
              <Select>
                <Option value="admin">Administrador</Option>
                <Option value="operator">Operador</Option>
                <Option value="auditor">Auditor</Option>
              </Select>
            </Form.Item>



          </Form>
        </Spin>
      </Modal></>
  );
};

export default UserRegister;
