import React , { useState, useEffect }  from 'react';

import Axios from './../Axios';
import { PlusOutlined ,EditOutlined } from '@ant-design/icons';
import {Table, Result, Button, DatePicker,Select} from 'antd';

import UserAssociate from '../Common/UserAssociate';
import RemoveReporter from '../Common/RemoveReporter';
import ReporterEdit from '../Common/ReporterEdit';

const Reporters = ({payload}) => {

   const [isLoading, setIsLoading] = useState(false);
   const [registerVisible] = useState(false);
      const [editVisible] = useState(false);
   const [userList, setUserList] = useState([]);


   useEffect(() => {
     customFetch();
   }, []);




   const columns = [
     {
       title: "#",
       width: 30,
       dataIndex: "id",
     },
     {
       title: "Nombre",
       dataIndex: "name",

     },
     {
       title: "Apellidos",
       dataIndex: "last_name",

     },
     {
       title: "Email",
       dataIndex: "email",

     },
     {
       title: "Videos subidos",
       dataIndex: "videos_uploaded",
     },
     {
       title: "Videos subidos",
       dataIndex: "videos_uploaded",
     },


     payload.role == 'admin' ? {
       title: "Editar",
       key: "edit",
       dataIndex: 'id',
       render : (value, record) =>   <ReporterEdit user={record} onSuccess={()=>customFetch()} visible={editVisible}>
         <Button type="ghost"  style={{borderColor: '#00BCD4'}} icon={<EditOutlined /> }>Editar</Button>
       </ReporterEdit>,
     } : {},
      payload.role == 'admin' ? {
       title: "Eliminar",
       key: "delete",
       dataIndex: 'id',
       align: 'right',
       render: (value) =>  <RemoveReporter id={value} onSuccess={()=>customFetch()}/>
     }:{},

   ];

   const customFetch = async (params = {}) => {
    console.log("params:", params);
    setIsLoading(true);
    const response = await Axios.get('/inspectors',{params: {
        results: 1000,
        ...params
    }});
    setUserList(response.data.data);
    setIsLoading(false);
  };



  const handleTableChange = (pagination, filters, sorter) => {
  const pager = pagination;
  pager.current = pagination.current;

  
    customFetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    });
  };


  if( payload.role !='admin') return  <Result
    status="403"
    title="403"
    subTitle="Usted no está autorizado para ver esta página."
    extra={<Button href="/" type="primary">Regresar al inicio</Button>}
  />

  return (
    <>

      <h1 className="title-left">Usuarios reportantes</h1>

      <div className="filters">



        <div style={{display: 'inline'}}>
          <UserAssociate onSuccess={()=>customFetch()} visible={registerVisible}>
            <Button type="primary" icon={<PlusOutlined />}>Asignar usuario existente</Button>
          </UserAssociate>

        </div>


      </div>

      <Table
        scroll={{x: true}}
        rowKey={'id'}
        columns={columns}
        dataSource={userList}
        loading={isLoading}
        pagination={false}
        onChange={handleTableChange}
        />

    </>

  );

}


export default Reporters;
