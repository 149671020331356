import React from 'react'
import { Menu, Dropdown, Badge, Button, message } from 'antd';

import { Link } from 'react-router-dom';

import {
  DownOutlined,
  BellOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'

import {
  Session
} from 'bc-react-session';
import Axios from '../Axios';


const {
  payload
} = Session.get();


export const Help =  () => {

  return <React.Fragment>
    <Link to="/help"  style={{marginRight: 15, color: '#666'}}><QuestionCircleOutlined />  </Link>
  </React.Fragment>

}

export const CompanyLogo =  ({logo, href = '/'}) => {

  return <div className="header-logo">
    <a href={href} style={{marginRight: 15, color: '#666'}}>
    <img style={{maxHeight: 30, marginTop: -8}} src={logo}/> </a>
  </div>

}
export const Notifications =  ({count = 0}) => {

  return <React.Fragment>
    <a href="#" style={{marginRight: 15, color: '#666'}}><BellOutlined />
      <Badge count={count} style={{marginTop: '-22px',marginLeft: '-10px'}}/>
    </a>
  </React.Fragment>

}
export  class AvatarDropdown  extends React.Component {


  logout = () => {  



    Axios.get('/logout', { crossdomain: true })
      .then( (response) =>  {

        const {success, data} = response.data;

        if (!success ) {
          message.error(response.data?.message);
          return;
        }

         window.location.href = '/logout';


      })
      .catch( (error) =>  {
      
        message.error(error.response.data?.message);
       
      });

    }


  render(){

    console.log(payload);

    let menu = (
      <Menu>
          {this.props.from !='pos' &&  <Menu.Item key="1">
          <a href="#"> Configuración</a>
        </Menu.Item>}

        <Menu.Divider />
      
        <Menu.Item key="3"><a href="#" onClick={this.logout}> Cerrar sesión</a></Menu.Item>
      </Menu>
    );


    return <React.Fragment>
      <Dropdown overlay={menu} trigger={['click']}>
        <a className="ant-dropdown-link" href="#">
          {payload.first_name} <DownOutlined />
        </a>
      </Dropdown>
    </React.Fragment>
  }

}
