import React , { useState, useEffect }  from 'react';
import {DeleteOutlined  } from '@ant-design/icons';
import {Button, message, Popconfirm } from 'antd';
import Axios from './../Axios';


const RemoveReporter = ({id, onSuccess}) => {

  const [loading, setLoading]  = useState(false);
  const [reverted, setReverted]  = useState(false);

  const removeNow = async () => {
    setLoading(true);
    try {
        const response = await Axios.post('/inspectors/'+id+'/remove');
        setLoading(false);
        if(response.data.success){
              message.success(response.data.message);
              if( onSuccess) onSuccess(id);
        }else {
              message.error(response.data.message);
        }

    } catch (e) {
              setLoading(false);
        message.error(e.message);

    }
  }

  return <Popconfirm
    title="¿Realmente deseas desasociar este Usuario?"
    onConfirm={removeNow}
    okText="Si"
    cancelText="No"
         ><Button loading={loading}  icon={<DeleteOutlined/>}>Eliminar</Button></Popconfirm>
}

export default RemoveReporter;
