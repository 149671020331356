// eslint-disable-line

window.setTitle = function (title) {
  document.title = title + ' - Simplefact.pe';
}

window.scrollTop = function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
}


window.isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (window.isMobile.Android() || window.isMobile.BlackBerry() || window.isMobile.iOS() || window.isMobile.Opera() || window.isMobile.Windows());
    }
};
