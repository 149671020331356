import React, {useState, useEffect} from 'react'
import { Alert, List, Tag , Spin} from 'antd';
import Axios from  '../Axios';
import VideoStatus from './VideoStatus';

const Fined24Alert = ({videoId,license}) => {

  const uri = '/videos/'+videoId+'/fines24';
  const [fines, setFines] = useState([]);
  const [plate, setPlate] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetch = async () => {
      setLoading(true);
      const response = await Axios.get(uri);
      if( response.data ){
            setFines(response.data )
       if( response.data.length > 0)
                setPlate(response.data[0].vehicle_plate);
      }

        setLoading(false);
  };
  useEffect(()=>{
      fetch();
  },[]);


  useEffect(()=>{
      fetch();
  },[license]);

    if( loading) return <Spin><span style={{padding: 10}}>Buscando multas...</span></Spin>

    const Items  =  () =>  <List
      style={{marginTop: 8}}
      header={<div>Atenciones</div>}
      footer={<a target="_blank" href={"/videos?search="+plate}>Ver todas &rarr;</a>}
      bordered
      dataSource={fines}
      renderItem={item => (
        <List.Item>
          <VideoStatus status={item.status} statusName={item.status_name}/> {item.created_at} <Tag>{item.penalty ? item.penalty.infringement_code : '' }</Tag>
          <a  target="_blank" href={"/videos/"+item.id}>#{item.id}</a>
        </List.Item>
      )}   />

    if( fines.length == 0) return <Alert
      style={{marginBottom: 10, marginTop: 10}}
      message="Sin multas recientes"
      description={<span>La placa no tiene multas recientes</span>}  type="success" showIcon   />
   return <Alert
     style={{marginBottom: 10, marginTop: 10}}
     message="Multas recientes"
     description={<span>Vehículo con multa en las últimas 24 horas: <br/>
       <Items/>
     </span>}  type="error" showIcon   />
}
export default Fined24Alert;
