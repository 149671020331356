import axios from 'axios'
import {message} from 'antd'

import {Session} from 'bc-react-session';

const Axios = axios.create({
   baseURL: process.env.REACT_APP_API_BASE
});

const {payload} = Session.get();

export const setAuthHeader = (token) => {

   if(token ){
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
   }
   // Axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:3000';
   // Axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'panel.vipa.io';
   //  Axios.defaults.headers.common['Accept'] = 'application/json';
   Axios.defaults.headers.common['Content-Type'] =  'application/json';

}


Axios.interceptors.response.use(function (response) {


    return response;
  }, function (error) {

    if( error.response && error.response.status == 401) {
          Session.destroy();

        window.location.href = '/login?expired=true';


    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });

// Set the initial header from storage or something (should surround with try catch in actual app)
setAuthHeader(payload.api_token || false);

export default Axios;
