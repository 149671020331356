import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import { CameraOutlined } from '@ant-design/icons';
import Axios from './../Axios';

const propTypes = {
  player: PropTypes.object,
  className: PropTypes.string
};

export default class SnapButton extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }


   dataURItoBlob = (dataURI)  => {


      var binary = atob(dataURI.split(',')[1]);
      var array = [];
      for(var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
  }

   capture = (video, scaleFactor) =>  {
    	if(scaleFactor == null){
    		scaleFactor = 1;
    	}


    	var w = video.videoWidth * scaleFactor;
    	var h = video.videoHeight * scaleFactor;


    	var canvas = document.createElement('canvas');
    		canvas.width  = w;
    	    canvas.height = h;
    	var ctx = canvas.getContext('2d');
    		ctx.drawImage(video, 0, 0, w, h);

        console.log("Canvas", canvas);

        return canvas;
  }

   uploadSnap = (data, time) =>   {
    var formData = new FormData();
    var blob = this.dataURItoBlob(data);

    formData.append('imagedata', blob, 'temp.jpg');
    // formData.append('video_id', VIDEO_ID);
    formData.append('time', time);

    Axios.post('/videos/'+this.props.id+'/upload-screnshot', formData).then(data => {
        this.props.onSuccess(data.data);
    }).catch(errno => {
      console.log(errno);
    })

}

  handleClick() {

    this.props.onCapturing();

	var video  = document.querySelector('.video-react-video:first-of-type');
  var time = video.currentTime;



	var canvas = this.capture(video, 1);



  this.uploadSnap(canvas.toDataURL('image/jpeg',1), time);

  }

  render() {
    const { player, className } = this.props;
    const { currentSrc } = player;

    return (
      <a
        ref={c => {
          this.button = c;
        }}
        className={classNames(className, {
          'video-react-control': true,
          'video-react-button': true
        })}



        tabIndex="0"
        onClick={this.handleClick}
      ><CameraOutlined  style={{fontSize: 16,padding: '6px 10px',color: 'white'}}/></a>
    );
  }
}

SnapButton.propTypes = propTypes;
