import React , { useState }  from 'react';
import { Layout, Menu, Breadcrumb, Input } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  RiseOutlined,
  MenuOutlined ,
} from '@ant-design/icons';

import {pathToRegexp} from 'path-to-regexp'
import { BrowserRouter, Switch, Route ,Redirect , Link} from 'react-router-dom';

import {
  Session
} from 'bc-react-session';
import {AvatarDropdown, Notifications, Help, CompanyLogo} from '../Common/Header';

import Dashboard from '../Sections/Dashboard';
import Videos from '../Sections/Videos';
import VideoDetail from '../Sections/VideoDetail';
import Users from '../Sections/Users';
import Reporters from '../Sections/Reporters';
import Fines from '../Sections/Fines'
import Login from '../Pages/Login';
import Logout from '../Pages/Logout';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const { Search } = Input;
const session = Session.get();
const {
  payload
} = Session.get();

class Home extends React.Component {

    state = {
    collapsed: false,
    openKeys: ['dashboard'],
    selected: ['dashboard'],
  }

  onCollapse = (value) => {
    this.setState({collapsed: value});
  }

  onClickMenu = (e) => {
    this.setState({selected: [e.key] , collapsed: window.screen.width >=768 ? this.state.collapsed : true });
  }

  toggle = () => {
     this.setState({
       collapsed: !this.state.collapsed,
     });
   };

   componentDidMount (){
     this.initMenu()
   }

   initMenu = () => {

     let {openKeys, selected} = (this.state);
     let {location} = (this.props);

     const regex = pathToRegexp('/:group/:module?/:submodule?/:other?');
     let current =   regex.exec(location.pathname);

     console.log("Current", current);


     if(current){

        selected = !current[2] ? current[1] : [current[2]];
        openKeys = [current[1]];


     }else {
       openKeys = ['dashboard'];
       selected = ['dashboard'];
     }

     console.log( {
       openKeys: openKeys ,
       selected: selected,
     });

     this.setState({
       openKeys: openKeys ,
       selected: selected,
     })

 }

 onOpenChange = openKeys => {
      this.setState({ openKeys: openKeys});
};


onSearch = (value) => {


   window.location.href = '/videos?search='+ value;
}

onBreakpoint = (broken) => {

if( broken){

}

}


  render() {

  let {openKeys, selected, collapsed} = (this.state);


  if( payload.require_password_change ) {
      return <Redirect to="passwordchange"/>
  }
 if( payload.authority?.status === 'suspended' ) {
    return <Layout id="layout-main" style={{ minHeight: '100vh' }}>
            <div class="message__suspended">
                <p>El servicio de administración de videos se encuentra suspendido, para renovaciones por favor contáctanos.</p>

              
                <img width="100" style={{marginTop: 10, display: 'inline-block'}} src={'/images/logo.png'} alt="VIPA"/>
              </div>
        </Layout>
  } 



   return <Layout id="layout-main" style={{ minHeight: '100vh' }}>

      {payload.authority?.status === 'unpaid' && <div class="message__unpaid">
        El servicio será suspendido por no contar con contrato vigente, por favor contacte con el equipo VIPA para renovaciones.
      </div>}

     <Sider trigger={null} collapsed={collapsed} breakpoint={'xs'} onBreakpoint={this.onBreakpoint}  collapsedWidth={0} collapsible  onCollapse={this.onCollapse} style={{background:'#141414'}}>
       <div className="logo" style={{padding: collapsed ? '6px 6px' : '6px 20px'}} >
         <a href="/"><img src="/images/logo-web-white.png" style={{width: collapsed ? '50px' : 'auto'}}/></a>

       </div>
       <Menu
         forceSubMenuRender={true}
         onOpenChange={this.onOpenChange}
         onClick={this.onClickMenu}
         theme="light"
         openKeys={openKeys}
         selectedKeys={selected}
       mode="inline">

         <Menu.Item key="dashboard">
           <Link to="/">
             <PieChartOutlined />
             <span> Dashboard</span>
           </Link>
         </Menu.Item>


         <Menu.Item key="videos">
           <Link to="/videos">  <DesktopOutlined />
             <span>Videos</span></Link>
         </Menu.Item>


         <Menu.Item key="fines">
           <Link to="/fines">  <RiseOutlined />
             <span>Atenciones</span></Link>
         </Menu.Item>

         <SubMenu
           key="iam"
           title={
             <span>
               <UserOutlined />

               <span>Usuarios</span>
             </span>
           }
         >
           <Menu.Item key="users">  <Link to="/iam/users">Operadores</Link></Menu.Item>
           <Menu.Item key="reporters">  <Link to="/iam/reporters">Reportantes</Link></Menu.Item>
         </SubMenu>


       </Menu>
     </Sider>
     <Layout className="site-layout">
       <Header className="site-layout-background" style={{ padding: 0 }} >


         <span className="trigger" style={{marginLeft: 20, top: 2, position: 'relative'}}>
           <MenuOutlined style={{fontSize: 21}} onClick={ this.toggle}/>
         </span>

         <Search

           placeholder="Busca por placa o ID de video"
           onSearch={this.onSearch}
           style={{ width:  window.screen.width >= 768 ? 300 : 200 , marginLeft: 20,
             display: (collapsed  ? 'inline-flex' :    ( window.screen.width >= 768 ? 'inline-flex' : 'none') ) }}
         />

         <div className="right-header">

           <Notifications count={0}/>
           <AvatarDropdown/>


         </div>

       </Header>
       <Content style={{ margin: '0 16px' }}>
         {/* <Breadcrumb style={{ margin: '16px 0' }}>
           <Breadcrumb.Item>VIPA</Breadcrumb.Item>
           <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
         </Breadcrumb> */}
         <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>



           <Switch>
             <Route path="/" exact={true} render={(props) => <Dashboard payload={payload} {...props}/>} />
             <Route path="/videos" exact={true} render={(props) => <Videos payload={payload} {...props}/>} />
             <Route path="/videos/:id" exact={true} render={(props) => <VideoDetail payload={payload} {...props}/>} />
           </Switch>

           <Switch>

             <Route path="/fines" exact={true} render={(props) => <Fines payload={payload} { ...props}/>} />
           </Switch>


           <Switch>

             <Route path="/iam/users" exact={true} render={(props) => <Users payload={payload} {...props}/>} />
          <Route path="/iam/reporters" exact={true} render={(props) => <Reporters payload={payload} {...props}/>} />
        </Switch>


      </div>
    </Content>
    <Footer style={{ textAlign: 'center' }}>Aplicativos SAC ©2020 Todos los derechos reservados</Footer>
  </Layout>
</Layout>

}

}

export default Home;
