import React, { Component } from "react";
import PropTypes from "prop-types";

import { Select } from "antd";

import Axios from "./../Axios";

const { Option } = Select;

class SelectAjax extends Component {
  state = {
    value: "",
    items: [],
    loading: true,
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.url !== prevProps.url) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    console.log("--------UMOUNT----------");
  }

  fetch = (query) => {
    this.setState({ loading: true });

    console.log(query);

    Axios.get(this.props.url , {params: {search: query}}).then(
      (response) => {
        const { data } = response.data;

        this.setState({ loading: false, items: data }, ()=> {
          if(this.props.onLoaded) {
            this.props.onLoaded()
          }
        });

      }
    );
  };

  onChange = (value, res) => {
    // this.setState({value});

    console.log("Changed", value, res);
  };

  onSearch = (value) => {
    if ("children" != this.props.optionFilterProp) {
      this.fetch(value);
    }
  };

  render() {
    const { items, loading } = this.state;

    const options = !items
      ? []
      : items.map((a, i) => {
         if(!a) return;
         
          return (
            <Option
              key={i}
              data={a}
              value={a[this.props.keyvalue || "id"]}
            >
              {a[this.props.label || "name"] +
                (a[this.props.aditional]
                  ? " - " + a[this.props.aditional]
                  : "" || "")}
            </Option>
          );
        });

    return (
      <Select

        defaultActiveFirstOption
        optionFilterProp={this.props.optionFilterProp}
        onSearch={this.onSearch}
        loading={loading}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
        {...this.props}
      >
        {options}
      </Select>
    );
  }
}

SelectAjax.propTypes = {
  label: PropTypes.string,
  keyvalue: PropTypes.string,
};

export default SelectAjax;
