/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "video-react/dist/video-react.css";
import {
  Player,
  ForwardControl,
  ReplayControl,
  ControlBar,
  PlaybackRateMenuButton,
} from "video-react";
import queryString from "query-string";
import { Session } from "bc-react-session";
import Axios from "./../Axios";
import {
  DownloadOutlined,
  DesktopOutlined,
  CameraOutlined,
  FileImageOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import {
  Card,
  Empty,
  Drawer,
  message,
  Modal,
  Cascader,
  Spin,
  Row,
  Col,
  Input,
  Table,
  Button,
  DatePicker,
  Descriptions,
} from "antd";
import moment from "moment";

// import io from 'socket.io-client';

import { Link } from "react-router-dom";
import VideoStatus from "./../Common/VideoStatus";
import Fined24Alert from "./../Common/Fined24Alert";
import SnapButton from "../Utils/SnapButton";
import SelectAjax from "../Utils/SelectAjax";
import Form2 from "../FineForms/Form2";
import Form15 from "../FineForms/Form15";
import Form16 from "../FineForms/Form16";

const { RangePicker } = DatePicker;
const DATE_FORMAT = "DD/MM/YYYY hh:mm a";
const { payload } = Session.get();

const getLocked = () => {
  let items = [];
  try {
    items =
      localStorage.getItem("locked") != null
        ? JSON.parse(localStorage.getItem("locked"))
        : [];
  } catch (e) {}

  return items;
};

class Videos extends React.Component {
  state = {
    video: null,
    snapLoading: false,
    reviewVisible: false,
    showForm: false,
    isLoading: true,
    geocoding: false,
    pagination: { total: 0 },
    videos: [],
    options: [],
    filters: {},
    totalVideos: 0,
    updating: false,
  };

  // socket = io(process.env.REACT_APP_SOCKET_URL,{transports: ['websocket'], forceNew: true});

  hasEcludedUser = () => {
    if (payload.email.toLowerCase() === "sfttcvm.vipa@gmail.com") {
      return true;
    }
    return false;
  };

  download = () => {
    const { filters } = this.state;

    const hide = message.loading(
      "Estamos descargando tu reporte, puede tardar unos minutos...",
      0
    );

    Axios.get("/videos", {
      responseType: "blob",
      params: { export: true, ...filters },
    })
      .then((response) => {
        let blob = new Blob([response.data]);

        hide();

        let url = URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        a.download = "videos.xlsx";
        a.click();
      })
      .catch(() => {
        hide();
        message.error("Error al exportar documento");
      });
  };

  showVideo = (video) => {
    video.snaps = [];

    this.setState({ reviewVisible: true, geocoding: true, video: video });

    Axios.get("/videos/" + video.id + "/geocode")
      .then((response) => {
        this.setState({ video: response.data.data, geocoding: false });
      })
      .catch(() => {
        this.setState({ geocoding: false });
      });
  };

  closeVideo = async () => {
    // this.socket.emit('close-video', {videoId: video.id})

    this.setState({ reviewVisible: false }, () => {
      setTimeout(() => {
        this.setState({ showForm: false, video: null });
      }, 50);
    });
  };

  setHighlight = (videoId, lock) => {
    const { videos } = this.state;
    const list = [...videos];

    const pos = list.findIndex((a) => a.id === videoId);

    if (pos > -1) {
      list[pos].locked = lock;
    }

    this.setState({ videos: list });
  };

  setInitItems = () => {
    const lockeds = getLocked();

    const { videos } = this.state;

    videos.map((a) => {
      if (lockeds.indexOf(a.id) > -1) {
        a.locked = true;
      } else {
        a.locked = false;
      }
    });
  };

  customFetch = async (params = {}) => {
    // console.log("params:", params);
    this.setState({ isLoading: true });
    const { pagination } = this.state;

    const response = await Axios.get("/videos", {
      params: {
        results: 10,
        ...params,
      },
    });
    const pager = pagination;

    pager.total = response.data.meta.total;
    pager.current_page = response.data.meta.current_page;
    pager.last_page = response.data.meta.last_page;

    this.setState({
      pagination: pager,
      videos: response.data.data,
      isLoading: false,
    });
  };

  fetchOptionsCategories = async () => {
    const response = await Axios.get("/utils/enabled-categories/children");

    if (response.data) {
      this.setState({ options: response.data.data });
    }
  };

  componentDidMount = () => {
    this.fetchOptionsCategories();

    const listen = this.props.history.listen;

    listen(() => {
      const parsed = queryString.parse(window.location.search);

      // console.log(window.location.search );

      if (parsed.search && parsed.search.length > 0) {
        this.filterField("search", parsed.search);
      } else {
        this.customFetch();
      }
    });

    // this.socket.on('lock-video', async (data)=>{

    //    if( (data.user.id != payload.id)){

    //          this.setState({reviewVisible: false})
    //        // console.log("data", data.user);

    //        if( video && (data.videoId == video.id)){

    //            message.warning("Video tomado por " + data.user.first_name);
    //        }

    //        setLocked(data.videoId );
    //       this.setHighlight(data.videoId, true);
    //    }

    // });

    // this.socket.on('unlock-video', async (data)=>{

    //    setUnlocked(data.videoId);

    //    this.setHighlight(data.videoId, false);

    // });

    const parsed = queryString.parse(this.props.location.search);

    if (parsed.search && parsed.search.length > 0) {
      this.filterField("search", parsed.search);
    } else {
      this.customFetch();
    }
  };

  finishForm = (message, id) => {
    const { videos } = this.state;

    Modal.success({
      maskClosable: false,
      onOk: () => {
        this.closeVideo();

        const index = videos.findIndex((video) => video.id === id);
        if (index >= 0) {
          let vids = [...videos];
          vids.splice(index, 1);
          this.setState({ videos: vids });
        }

        if (videos.length < 2) {
          this.customFetch();
        }
      },
      content: message || "Error al generar atención contacte con soporte",
    });
  };

  filterField = (field, value) => {
    const { filters } = this.state;
    filters[field] = value;

    this.setState({ filters }, () => {
      this.customFetch(filters);
    });
  };
  resetFilters = () => {
    this.setState({ filters: {} }, () => {
      this.customFetch({});
    });

    this.setState({ daterangemoment: null });
  };
  sendModeration = async () => {
    const { video } = this.state;

    const response = await Axios.patch("/videos/" + video.id, {
      request_moderation: 1,
    });

    if (response.data.success) {
      message.success(response.data.message);
      this.closeVideo();
      this.customFetch();
    } else {
      message.error(response.data.message);
    }
  };

  deleteImage = async (snapId) => {
    this.setState({ snapLoading: true });

    const response = await Axios.delete("/videos/delete-snap/" + snapId);

    this.setState({ snapLoading: false });

    if (response.data.success) {
      message.success(response.data.message);
      const { video } = this.state;

      const vid = { ...video };
      vid.snaps = response.data.snaps;

      this.setState({ video: vid });
    } else {
      message.error(response.data.message);
    }
  };

  updateField = async (field, value, succesMessage) => {
    this.setState({ updating: true });

    const { video, videos } = this.state;
    const response = await Axios.patch("/videos/" + video.id, {
      [field]: value,
    });

    video[field] = value;
    this.setState({ video });

    if (response.data) {
      const data = [...videos];
      let pos = data.findIndex((value) => value.id === video.id);
      data[pos] = response.data.data;
      this.setState({ videos: data });
      message.success(succesMessage || "Actualizado con éxito");
    } else {
      message.error("No se pudo actualizar");
    }

    this.setState({ updating: false });
  };

  onChangeIncident = (value) => {
    if (value.length === 2) {
      this.updateField("infringement_type_id", value[1]);
    }
  };

  changeDate = (value) => {
    if (!value) {
      const { filters } = this.state;
      delete filters["daterange"];

      this.setState({ filters }, () => {
        this.customFetch(filters);
      });

      return;
    }

    const dates = [
      value[0].format("YYYY-MM-DD"),
      value[1].format("YYYY-MM-DD"),
    ];

    this.filterField("daterange", dates);
    this.setState({ daterangemoment: value });
  };

  changeAddress = (e) => {
    const { video } = this.state;
    video.address = e.target.value;
    this.setState({ video });
  };

  render() {
    const {
      video,
      videos,
      pagination,
      isLoading,
      snapLoading,
      reviewVisible,
      showForm,
      filters,
      options,
    } = this.state;

    const columns = [
      {
        title: "ID",
        width: 30,
        dataIndex: "id",
      },
      {
        title: "Desde",
        dataIndex: "upload_origin",
        render: (value) => (
          <span>
            {value === "camera" || value === "LP" ? (
              <CameraOutlined style={{ color: "#00bcd4" }} />
            ) : (
              <FileImageOutlined style={{ color: "#FF9800" }} />
            )}{" "}
            {value === "camera"
              ? "Cámara"
              : value === "gallery"
              ? "Galería"
              : value}
          </span>
        ),
        width: 100,
        // sorter: (a, b) => (a.name.first > b.name.first ? 1 : -1),
      },
      {
        title: "Subida",
        dataIndex: "device_creation_date",
        render: (value)=> moment(value).fromNow(),
      },
      {
        width: 180,
        title: "F.Grabación",
        dataIndex: "device_creation_date",
        sorter: true,
        render: (value)=> moment(value).format(DATE_FORMAT),
      },
      {
        title: "Placa",
        dataIndex: "vehicle_plate",
        render: (value) => <Link to={"/videos?search=" + value}>{value}</Link>,
      },

      {
        title: "Distrito",
        key: "municipality",
        dataIndex: "municipality",
      },

      {
        title: "Categoría",
        dataIndex: "infringement",
      },
      {
        title: "Preview",
        dataIndex: "cover_url_cdn",
        render: (value) =>
          value ? (
            <img alt="preview" className="img-preview" src={value} />
          ) : (
            "-"
          ),
      },
      {
        title: "Atender",
        key: "attend",
        align: "right",
        render: (value, record) =>
          record.status == 1 ? (
            record.locked ? null : (
              <Button
                onClick={() => this.showVideo(record)}
                style={{ borderColor: "#00BCD4" }}
              >
                Atender
              </Button>
            )
          ) : (
            <Link to={"/videos/" + record.id}>
              {" "}
              <Button
                ghost
                style={{ borderColor: "#00BCD4" }}
                icon={<ArrowRightOutlined />}
              >
                Detalles
              </Button>
            </Link>
          ),
      },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
      const pager = pagination;
      pager.current = pagination.current;

      const fs = this.state.filters;

      this.customFetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...fs,
      });
    };

    const updateSnaps = (data) => {
      const vid = { ...video };
      vid.snaps = data;
      this.setState({ video: vid, snapLoading: false });
    };

    const attendNow = () => {
      // this.socket.emit('open-video', {videoId: video.id, user: payload} )

      this.setState({ showForm: true });
    };

    const renderAuthorityForm = (video) => {
      const { authority_id } = video;
      if (authority_id == 15) {
        return (
          <Form15
            onSuccess={this.finishForm}
            visible={showForm}
            pid={video.id}
          />
        );
      }

      if (authority_id == 16) {
        return (
          <Form16
            onSuccess={this.finishForm}
            visible={showForm}
            pid={video.id}
          />
        );
      }

      return (
        <Form2 onSuccess={this.finishForm} visible={showForm} pid={video.id} />
      );
    };

    return (
      <>
        {video && (
          <Drawer
            bodyStyle={{ padding: 5 }}
            title={"Video #" + video.id}
            placement="right"
            maskClosable={false}
            closable={true}
            width={window.screen.width >= 768 ? "86%" : "100%"}
            height="100vh"
            onClose={() => this.closeVideo()}
            visible={reviewVisible}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button style={{ marginRight: 8 }}>Cancelar</Button>
                <Button
                  type="primary"
                  disabled={this.state.updating}
                  loading={this.state.geocoding || this.state.updating}
                  onClick={() => attendNow()}
                >
                  Atender ahora
                </Button>
              </div>
            }
          >
            <Spin spinning={this.state.geocoding}>
              <Row>
                <Col xs={24} md={10}>
                  <Player
                    crossOrigin="anonymous"
                    autoPlay={false}
                    fluid={false}
                    width={"100%"}
                    height={500}
                    playsInline
                    poster={video.cover_url_cdn}
                    src={video.video_url_cdn}
                  >
                    <ControlBar autoHide={false}>
                      <ReplayControl seconds={5} order={6.1} />
                      <ForwardControl seconds={5} order={6.2} />
                      <PlaybackRateMenuButton
                        rates={[3, 2, 1, 1.5, 0.5, 0.1]}
                        order={6.3}
                      />

                      <SnapButton
                        onCapturing={() => this.setState({ snapLoading: true })}
                        onSuccess={(data) => updateSnaps(data)}
                        id={video.id}
                        order={7}
                      />
                    </ControlBar>
                  </Player>

                  <Fined24Alert
                    videoId={video.id}
                    license={video.vehicle_plate}
                  />

                  <iframe
                    src={
                      "https://maps.google.com/maps?q=" +
                      video.lat_video +
                      "," +
                      video.lng_video +
                      "&z=15&output=embed"
                    }
                    width="100%"
                    height="450"
                    style={{ width: "100wh" }}
                    frameBorder="0"
                  >
                    {" "}
                  </iframe>

                  <Drawer
                    onClose={() => this.setState({ showForm: false })}
                    visible={showForm}
                    title="Atender video"
                    width={
                      window.innerWidth >= 768
                        ? window.innerWidth / 2 + 250
                        : "100%"
                    }
                    closable={true}
                    maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.74)" }}
                  >
                    {renderAuthorityForm(video)}
                  </Drawer>
                </Col>

                <Col md={14}>
                  <div style={{ padding: "10px 20px" }}>
                    <Row gutter={16}>
                      <Col xs={24} md={8} span={8}>
                        <Card title="Placa" bordered={false}>
                          <Input
                            onBlur={(e) =>
                              this.updateField("vehicle_plate", e.target.value)
                            }
                            defaultValue={video.vehicle_plate}
                          />
                        </Card>
                      </Col>
                      <Col xs={24} md={8} span={8}>
                        <Card title="Estado" bordered={false}>
                          <VideoStatus
                            status={video.status}
                            statusName={video.status_name}
                          />
                        </Card>
                      </Col>
                      <Col xs={24} md={8} span={8}>
                        {payload.role == "admin" ? (
                          <Card title="Moderar" bordered={false}>
                            <Button onClick={this.sendModeration}>
                              Enviar a moderación
                            </Button>
                          </Card>
                        ) : null}
                      </Col>
                    </Row>

                    <Descriptions bordered column={{ xs: 1, sm: 1 }}>
                      <Descriptions.Item label="Inspector">
                        {video.inspector_name ? video.inspector_name : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Fecha Grabación">
                        {moment(video.device_creation_date).format(DATE_FORMAT)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Fecha de Subida">
                        {moment(video.created_at).format(DATE_FORMAT)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Distrito/Lugar">
                        <SelectAjax
                          label={"name"}
                          onSelect={(value) =>
                            this.updateField(
                              "municipality_id",
                              value,
                              "Municipalidad actualizada"
                            )
                          }
                          style={{ width: "100%" }}
                          value={video.municipality_id}
                          url={"/utils/enabled-places"}
                          placeholder="Asignar Municipalidad"
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="Tipo de incidente">
                        <Cascader
                          defaultValue={[
                            video.infringement_type_id_parent,
                            video.infringement_type_id,
                          ]}
                          fieldNames={{
                            label: "name",
                            value: "id",
                            children: "childs",
                          }}
                          options={options}
                          style={{ width: "100%" }}
                          onChange={this.onChangeIncident}
                          placeholder="Selecciona"
                        />
                      </Descriptions.Item>

                      <Descriptions.Item label="Dirección">
                        <Input
                          onChange={this.changeAddress}
                          onBlur={(e) =>
                            this.updateField("address", e.target.value)
                          }
                          value={video.address}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="GPS">
                        <a
                          target="blank"
                          rel="noreferrer"
                          href={
                            "https://www.google.com/maps/search/?api=1&query=" +
                            video.lat_video +
                            "," +
                            video.lng_video
                          }
                        >
                          {video.lat_video},{video.lng_video}
                        </a>
                      </Descriptions.Item>

                      <Descriptions.Item label="Subido desde">
                        {(video.upload_origin === "camera"
                          ? "Cámara"
                          : video.upload_origin === "gallery"
                          ? "Galería"
                          : video.upload_origin) +
                          " de " +
                          video.upload_from}
                      </Descriptions.Item>

                      {video.comments && (
                        <Descriptions.Item label="Comentarios">
                          {video.comments}
                        </Descriptions.Item>
                      )}

                      {/* {video.penalties.length > 0 && <Descriptions.Item label="Detalles de la atención">{video.penalties.map((a,i)=>{
                    return <div key={i}>Tipo: {a.infringement_code}<br/>Comentario: {a.comments}</div>
                  })}</Descriptions.Item>} */}

                      <Descriptions.Item label="Fotos">
                        <Spin spinning={snapLoading}>
                          {video.snaps && video.snaps.length > 0 ? (
                            <div style={{ display: "flex" }}>
                              {video.snaps.map((snap, key) => {
                                return (
                                  <div key={key} className="img-snap">
                                    <Button
                                      onClick={() => this.deleteImage(snap.id)}
                                      size="small"
                                      style={{ marginBottom: 5 }}
                                    >
                                      Delete
                                    </Button>{" "}
                                    <img src={snap.url_cdn} />
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <Empty description={"No hay fotos"} />
                          )}
                        </Spin>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </Col>
              </Row>
            </Spin>
          </Drawer>
        )}

        <h1 className="section-title">
          <DesktopOutlined /> Videos para atender
        </h1>

        <div className="filters">
          <RangePicker
            ranges={{
              Hoy: [moment(), moment()],
              Ayer: [
                moment().add(-1, "days").startOf("day"),
                moment().add(-1, "days").endOf("day"),
              ],
              "Este mes": [moment().startOf("month"), moment().endOf("month")],
              "El mes pasado": [
                moment().add(-1, "months").startOf("month"),
                moment().add(-1, "months").endOf("month"),
              ],
              "Ultimos 3 meses": [
                moment().add(-3, "months").startOf("month"),
                moment().add(-3, "months").endOf("month"),
              ],
            }}
            onChange={(value) => this.changeDate(value)}
          />

          <SelectAjax
            value={filters.municipality}
            style={{ width: 200, textAlign: "left" }}
            onChange={(value) => this.filterField("municipality", value)}
            label={"name"}
            url={"/utils/enabled-places"}
            placeholder="Todos los lugares"
          />

          <SelectAjax
            value={filters.category}
            style={{ width: 240, textAlign: "left" }}
            onChange={(value) => this.filterField("category", value)}
            label={"name"}
            url={"/utils/enabled-categories"}
            placeholder="Todos las categorías"
          />

          <Button
            type="dashed"
            danger
            style={{ marginLeft: 10 }}
            onClick={this.resetFilters}
          >
            Resetear
          </Button>

          <div style={{ display: "inline" }}>
            <Button
              type="primary"
              onClick={this.download}
              icon={<DownloadOutlined />}
            >
              Exportar
            </Button>
          </div>
        </div>

        <Table
          scroll={{ x: true }}
          showSorterTooltip={true}
          rowClassName={(record) => (record.locked == true ? "tr-locked" : "")}
          //    expandable={{
          //   expandedRowRender: record => <p style={{ margin: 0 }}>{record.id}</p>,
          // }}
          // rowSelection={rowSelection}
          rowKey={"id"}
          columns={columns}
          dataSource={videos}
          loading={isLoading}
          onChange={handleTableChange}
          footer={() => {
            return (
              <div>
                TOTAL PENDIENTES:{" "}
                <b style={{ color: "#00bcd4" }}>{pagination.total}</b>{" "}
                <div style={{ float: "right" }}>
                  Página {pagination.current_page} / {pagination.last_page}{" "}
                </div>
              </div>
            );
          }}
          pagination={pagination}
        />
      </>
    );
  }
}

export default Videos;
