// React Required
import React, { Component, useEffect, useState } from "react";

import { Session } from "bc-react-session";

// Create Import File
import "./App.css";

import "moment/locale/es";
import moment from "moment";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";

import Login from "./Pages/Login";
import Logout from "./Pages/Logout";
import Home from "./Pages/Home";
import PasswordChange from "./Pages/PasswordChange";

// import VideoCall from './Sections/VideoCall';

const session = Session.get();

class App extends Component {
  componentDidMount() {
    moment().locale("es");
  }

  render() {
    if (!session.isValid && window.location.pathname !== "/login") {
      return <Redirect to="/login" />;
    }
    if (/logout|login|passwordchange/.test(window.location.pathname)) {
      return (
        <Switch>
          <Route
            path="/login"
            exact={true}
            render={(props) => <Login {...props} />}
          />
          <Route
            path="/logout"
            exact={true}
            render={(props) => <Logout {...props} />}
          />

          <Route
            path="/passwordchange"
            exact={true}
            render={(props) => <PasswordChange {...props} />}
          />
        </Switch>
      );
    }

    return <Home {...this.props} />;
  }
}

export default App = withRouter(App);
