import React, { useState, useEffect } from "react";

import { Form, Input, Button, Checkbox, Spin, message, Alert } from "antd";
import queryString from "query-string";

import { Session } from "bc-react-session";

import "./Login.css";
import Axios from "axios";

const axios = require("axios");

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;
axios.defaults.headers.common = {
  ...axios.defaults.headers.common,
  // 'Access-Control-Allow-Origin': 'http://localhost:3000',
  "Content-Type": "application/json",
  Accept: "application/json",
};
axios.defaults.preflightContinue = true;

const session = Session.get();

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [twoSteps, setTwoSteps] = useState(false);
  const [code, setCode] = useState(null);
  const [pid, setPid] = useState(null);
  const [shoudChange, setPasswordChange] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.expired) {
      Axios.get("/logout", { crossdomain: true })
        .then((response) => {
          message.error(
            "La sesión a expirado o has accedido en otro dispositivo. Por favor vuelve a iniciar sesión."
          );
        })
        .catch((err) => {
          message.error("Sesion expira por favor inicia nuevamente!");
        });
    }
  }, []);

  if (session.isValid) {
    window.location.href = "/";
    return;
  }

  const onFinish = (values) => {
    setLoading(true);

    axios
      .post("/login", values, { crossdomain: true })
      .then((response) => {
        setLoading(false);

        const { success, pid, code } = response.data;

        if (!success) {
          message.error(response.data.message);
          return;
        }
        setPid(pid);
        setTwoSteps(true);
        setCode(code);

        // Session.start({
        //   payload: data,
        //   expiration: 86400000,
        // });

        // window.location.href = '/';
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const verifyNow = () => {
    setLoading(true);

    axios
      .post("/verify", { code, pid }, { crossdomain: true })
      .then((response) => {
        setLoading(false);

        const { success, data } = response.data;

        if (!success) {
          message.error(response.data?.message);
          return;
        }

        Session.start({
          payload: data,
          expiration: 86400000,
        });

        if (!data.require_password_change) {
          window.location.href = "/";
        } else {
          window.location.href = "/passwordchange";
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.response.data?.message);
        console.log(error);
      });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (code?.length === 6) {
      verifyNow();
    }
  }, [code]);

  return (
    <div>
      <img
        alt="logo"
        src="/images/logo-web-white.png"
        style={{ margin: "50px auto", width: 120, display: "block" }}
      />
      <Form
        {...layout}
        name="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Spin spinning={loading}>
          {twoSteps ? (
            <React.Fragment>
              <Alert
                message="Ingresa el código de verificación enviado al correo:"
                type="info"
              />

              <Input
                required
                minLength={6}
                maxLength={6}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="two_input"
                placeholder="Código"
              />

              <Button
                style={{ marginTop: 2, width: "100%" }}
                type="primary"
                onClick={verifyNow}
              >
                Verificar
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa tu usuario!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa tu contraseña!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                {...tailLayout}
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>Recordarme</Checkbox>
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Iniciar sesión
                </Button>
              </Form.Item>
            </React.Fragment>
          )}
        </Spin>
      </Form>{" "}
    </div>
  );
};

export default Login;
