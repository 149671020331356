import React , { useState, useEffect }  from 'react';
import {SwapOutlined } from '@ant-design/icons';
import {Button, message, Popconfirm } from 'antd';
import Axios from './../Axios';


const VideoRevert = ({id, onSuccess}) => {

  const [loading, setLoading]  = useState(false);
  const [reverted, setReverted]  = useState(false);

  const revertVideo = async () => {
    setLoading(true);
    try {
        const response = await Axios.post('/fines/'+id+'/revert');
        setLoading(false);
        if(response.data.success){
              message.success(response.data.message);
              if( onSuccess) onSuccess(id);
        }else {
              message.error(response.data.message);
        }

    } catch (e) {
              setLoading(false);
        message.error(e.message);

    }
  }

  return <Popconfirm
    title="¿Realmente deseas revertir la atención del video?"
    placement="leftTop"
    onConfirm={revertVideo}
    okText="Si"
    cancelText="No"
         ><Button loading={loading}  icon={<SwapOutlined/>}>Revertir</Button></Popconfirm>
}

export default VideoRevert;
