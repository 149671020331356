import React , { useState, useEffect }  from 'react';

import { Tag } from 'antd';



const VideoStatus = ({status, statusName}) => {

    if( status == 1)   return <Tag color="processing">{statusName}</Tag>;

    if( status == 5 ||  status == 2 )   return <Tag color="success">{statusName}</Tag>;

    return <Tag color="default">{statusName}</Tag>;

}
export default VideoStatus;
